export default {
  badge: 'Знак',
  close: 'Закрити',
  dataIterator: {
    noResultsText: 'В результаті пошуку нічого не знайдено',
    loadingText: 'Завантаження...',
  },
  dataTable: {
    itemsPerPageText: 'Рядків на сторінці:',
    ariaLabel: {
      sortDescending: 'Відсортовано за спаданням.',
      sortAscending: 'Відсортовано за зростанням.',
      sortNone: 'Не відсортовано.',
      activateNone: 'Активувати, щоб видалити сортування.',
      activateDescending: 'Активувати, щоб відсортувати за спаданням.',
      activateAscending: 'Активувати, щоб відсортувати за зростанням.',
    },
    sortBy: 'Відсортувати за',
  },
  dataFooter: {
    itemsPerPageText: 'Елементів на сторінці:',
    itemsPerPageAll: 'Всі',
    nextPage: 'Наступна сторінка',
    prevPage: 'Попередня сторінка',
    firstPage: 'Перша сторінка',
    lastPage: 'Остання сторінка',
    pageText: '{0}-{1} з {2}',
  },
  datePicker: {
    itemsSelected: '{0} вибрано',
    nextMonthAriaLabel: 'Наступного місяця',
    nextYearAriaLabel: 'Наступного року',
    prevMonthAriaLabel: 'Попередній місяць',
    prevYearAriaLabel: 'Попередній рік',
  },
  noDataText: 'Немає даних для відображення',
  carousel: {
    prev: 'Попередній слайд',
    next: 'Наступий слайд',
    ariaLabel: {
      delimiter: 'Слайд {0} з {1}',
    },
  },
  calendar: {
    moreEvents: 'Ще {0}',
  },
  fileInput: {
    counter: '{0} файлів',
    counterSize: '{0} файлів ({1} загалом)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: 'Навігація по сторінках',
      next: 'Наступна сторінка',
      previous: 'Попередня сторінка',
      page: 'Перейти на сторінку {0}',
      currentPage: 'Поточна сторінка, Сторінка {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  rating: {
    ariaLabel: {
      item: 'Rating {0} of {1}',
    },
  },
}
